@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

	/* Force table to not be like tables anymore */
	table.prop_search_table,
	.prop_search_table thead,
	.prop_search_table tbody,
	.prop_search_table th,
	.prop_search_table td,
	.prop_search_table tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	.prop_search_table thead tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

	.prop_search_table tr {
		margin: 0 0 1rem 0;
	}

	.prop_search_table tr:nth-child(odd) {
		// background: #ccc;
	}

	.prop_search_table td {
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee;
		position: relative;
		padding-left: 50% !important;
	}

	.prop_search_table td:before {
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 0;
		left: 6px;
		width: 45%;
		padding-right: 10px;
		white-space: nowrap;
	}

	/*
		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
	.prop_search_table td:nth-of-type(1):before {
		content: "Property :";
	}

	.prop_search_table td:nth-of-type(2):before {
		content: "CityName :";
	}

	.prop_search_table td:nth-of-type(3):before {
		content: "StateName :";
	}

	.prop_search_table td:nth-of-type(4):before {
		content: "Action :";
	}


	.prop_search_table input,
	.dList[type='text/x-template'],
	.b-shadow input[type='email'],
	.b-shadow input[type='number'] {
		width: 98% !important;
	}

	.b-shadow .e-input-group {
		width: 100% !important;
	}
}

.Table_grp {
	Background: #fff;
	padding: 10px;
	position: relative;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	height: 100%;
}

.popupClose {
	position: absolute;
	top: 0px;
	right: 5px;
	font-size: 22px;
}

.popupClose:hover {
	cursor: pointer;
}

.table thead tr:last-child th {
	text-align: center;
}

// search style start
.ghpropertySearch {
	border: 1px solid #ddd;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	padding: 5px 8px;
	width: 250px;
	border-radius: 5px;
	background: #edf2f6;
}

::placeholder {
	color: #bdbdbd !important;
	opacity: 1;
	/* Firefox */
}

:-ms-input-placeholder {
	/* Internet Explorer 10-11 */
	color: #bdbdbd !important;
}

::-ms-input-placeholder {
	/* Microsoft Edge */
	color: #bdbdbd !important;
}

.ghpropertySearch:focus {
	border: none;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.ghpropertySearch:focus-visible {
	border: none;
	outline: none;

}

.table th,
.table td {
	padding: 0.25rem;
	vertical-align: middle;
}

@media only screen and (max-width: 760px) {
	.prop_search_table th {
		display: none;
	}
}