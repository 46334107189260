
.error_message {
	// width: 98vw;
    // height: 100vh;
	// position: absolute;
	// top: 0px;
	// left: 0px;
	// background: rgba(0, 0, 0, 0.5);
	// z-index: 1148;
	// display: flex;
	// align-items: center;
	// justify-content: center;
	// transition: visibility 0s linear 0.33s, opacity 0.33s linear;

	.error_Message_content {
	//	height: 150px;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		// padding: 40px 20px 20px;
		width: 400px;
		//background: #e04a4a;
		background: #fff;
		position: relative;
		border-radius: 10px;
		position:fixed;
		z-index:9999;
		right: 20px;
		bottom:20px;
		
		-webkit-box-shadow: 0 0 10px #000;
        box-shadow: 0 0 10px #000;
		
        transition: all .9s ease-in-out;
        
	}
.error_MSg_Con {
	// border-bottom: 1px solid #ff0000;
    position: relative;
	padding: 11px 10px 7px 10px;
	border-radius: 5px 5px 0px 0px;
    // background: #ff9900e3;
	background: rgb(255, 0, 0) ;
    text-align: center;
    font-weight: bold;
span { color:#fff !important;}
}
// .error_content p::before {
// 	content: "* ";
//   }
.error_content.t-center { border-bottom:1px solid #ddd !important; margin-bottom: 14px !important;	 }
.error_content {  min-height: 50px; border-radius: 0px 0px 5px 5px; 
	// border: 3px solid #ddd;
    border-top: 0px;
	display: flex;
    flex-wrap: wrap;
	align-items: center; }
.error_content p { margin-bottom:10px; flex: 1 0 100%;  color:#000 !important;}

	.close_but {
		position: absolute;
		top: 11px;
        right: 10px;
		padding: 2px 9px;
		margin-top: -4px;
		border-radius: 13px;
		height: 26px;
		font-weight: bold;
	&:hover {
		background:#ff0000 !important;
		 color:#fff;
		 cursor:pointer;
	}
	}

	.msg {
	//	padding: 20px 40px;
		border-radius: 10px;
	//	color: #fff;
	}

	.msg.error_warning {
	//	background: #ff0000;

	}

	.msg.error_success {
		// background: #00cf7c;


	}
}