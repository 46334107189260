.addFieldsbg {
    background: rgba(0, 0, 0, 0.5);
    z-index: 1100;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;


    .AddFieldsBlock {

        background: #fff;
        box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
        padding: 10px;
        z-index: 1110;
        width: 50%;
        min-width: 400px;
        border-radius: 10px;
        max-width: 450px;



        .closeafields {
            position: absolute;
            top: -6px;
            right: 5px;
            padding: 6px 10px;
            border-radius: 50px;


            &:hover {
                cursor: pointer;
                background: #ddd;

            }
        }

        .AddFields {

            .AddFieldsheader {
                padding: 0px 10px 5px 0px;
                border-bottom: 1px solid #dc1501;
                position: relative;
            }

            .AddFieldsbody {}

            .AddFieldsfooter {
                padding: 10px 0px 5px 0px;
                text-align: center;
            }

        }
    }
}