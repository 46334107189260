

@keyframes popupdisplay {
    0%    { transform: scale(0.2); }
    100%  { transform: scale(1); }
  }
  


.changeEditor {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1002;
    left: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:rgba(0,0,0,0.5);
    color:#000;

.editorBody {
    width: 80%;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 10px #2b2a2a;
    max-height: 90%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 5px;
    border-bottom: 5px solid #ddd;
    animation: popupdisplay 0.4s
}
.header { 
    width: 100%;
    border-radius: 5px 5px 0px 0px;
    padding: 11px 10px 7px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ff0000;
}
.body { padding: 5px; 

.col-md-4 { margin:8px 0px;
.change_editor {
    //background:#ddd;
    //padding: 10px;
}
}

} 
.footer { height:50px;     display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #dde9f8; } 
}
.form-icon + .form-control, .form-icon + .rdl-filter, .dual-listbox .form-icon + .dual-listbox__search, div.dataTables_wrapper div.dataTables_filter .form-icon + input {
    padding-left: 5px;
}

.form-icon.form-icon-left {
    right:0px;
}
.input_box { 
    height: 34px;
    border: none;
    border: 1px solid #ddd;
    border-radius: 4px;
}


.changeEditor .body .col-md-4 span{
    //background: #efefef;
    padding: 3px;
    border-radius: 5px;
    color: #000;
}

#cancel_booking {
     background: rgb(221, 233, 248);
     padding: 7px 5px 7px 0px;
    border-radius: 5px; 
}